import { useState } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import questionLog from "./QuestionLog";
import About from "./About";

export default function Question() {
    const questions = questionLog();
    const [aboutClicked, setAboutClicked] = useState(false);
    
    const [rand, setRand] = useState(Math.floor(Math.random() * questions.length));

    function randNumber(max)
    {
        return Math.floor(Math.random() * max)
    }

    function handleAbout()
    {
        setAboutClicked(!aboutClicked);
    }

    const newQuestion = () => {
        if(aboutClicked){
            setAboutClicked(false)
        }
        const oldRand = rand;
        let newRand = randNumber(questions.length);
        while(oldRand === newRand)
        {
            newRand = randNumber(questions.length);
        }
        setRand(newRand);
    }

    return(
        <div className="z-10">
            {isMobile && (<div className="text-2xl text-center fixed solidBg w-full top-0">TALK2ME</div>)}
            {isDesktop && (
            <div className="fixed top-0 w-full solidBg">
                <div className="flex flew-row justify-between m-4 text-2xl">
                    <div onClick={handleAbout} className="cursor-pointer">
                        ABOUT
                    </div>
                    <div>
                        TALK2ME
                    </div>
                    <div onClick={newQuestion} className="cursor-pointer">
                        NEUE FRAGE
                    </div>
                </div>
            </div> 
            )}
            {
                aboutClicked && <About />
            }
            {
                !aboutClicked && (
                    <div className="boldText m-8 mt-20 mb-20 break-words text-left lg:text-center lg:grid lg:place-items-center lg:h-full"> 
                        {questions[rand]}
                    </div>
                )
            }
            { isMobile && 
            (
                <div className="fixed bottom-0 w-full solidBg">
                    <div className="flex flew-row justify-between m-4 text-2xl">
                        <div onClick={handleAbout}>
                            ABOUT
                        </div>
                        <div onClick={newQuestion} className="cursor-pointer">
                            NEUE FRAGE
                        </div>
                    </div>
                </div>
            )
            }
            
        </div>
    )
}