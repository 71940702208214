export default function About() {
    return (
        <div className="text-2xl m-8 mt-20 break-words text-left mb-20">
        Zwischen Unbekannten und Bekannten. <br/>
        Zwischen Kritik und Konsens. <br/>
        Zwischen Dialog und Diskurs. <br/>
        Zwischen Tiefgründig und Oberflächlich. <br/>
        Zwischen Künstlich und Natürlich. <br/>
        Zwischen Sprechen und Schweigen. <br/>
        Zwischen Frage und Frage.<br/>
        Zwischen Frage und Antwort.<br/>
        <br/><br/><br/>

        Talk2Me ist eine Idee, eine Aufforderung, ein Wunsch, eine Erkenntnis, ein Gefühl, ein Prozess, ein Körper, ein Katalog.
        <br/><br/><br/>

        Talk to us : studio.zeitnah@posteo.de
        <br/><br/><br/>
        
        Max Frisch, Freunde und Fremde formten diesen Fragenkatalog. Thx!

        </div>
    )
}