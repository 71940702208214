import mobileLine from './LinieGelb.svg';
import desktopLinie from './LinieGelb_desk.svg'
// import './App.css';
import Question from './components/Question';
import { isDesktop, isMobile } from 'react-device-detect';

function App() {
  return (
    <div>
      <Question />
      {/* {isMobile && (
        <div className="fixed negativeZ bottom-10 right-0 h-full">
          <img src={mobileLine} alt={"eine gelbe linie"} className="h-full right-0"/>
        </div>
      )}
      {isDesktop && (
        <div className="fixed negativeZ bottom-0 h-full">
          <img src={desktopLinie} alt={"eine gelbe linie"} className="h-full right-0"/>
        </div>
      )} */}

    </div>
  );
}

export default App;
